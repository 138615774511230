import React from 'react'

// Components
import Reviews from 'components/flex/Reviews/Reviews'
import ReviewHighlighted from 'components/flex/Reviews/ReviewHighlighted'
import ReviewsLink from './ReviewLink'

export interface ReviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Reviews
  location?: any
}

interface PostProps {
  [key: string]: any
}

const ReviewShell: React.FC<ReviewProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    default: Reviews,
    highlighted: ReviewHighlighted,
    link: ReviewsLink,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default ReviewShell
