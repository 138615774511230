import React from 'react'
import styled from 'styled-components'
import StarRatings from 'react-star-ratings'
import Plaatjie from '@ubo/plaatjie'

// Hooks
import useWrapper from 'hooks/useWrapper'

// Elements
import ParseContent from 'components/shared/ParseContent'
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'

// Images
import Chevron from 'img/chevronbig.inline.svg'

// Interface
import { ReviewProps } from 'components/flex/Reviews/ReviewShell'

const AverageWrapper = styled.div`
  position: relative;
  z-index: 2;
`

const AverageBlock = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  & span {
    color: ${({ theme }) => theme.color.light};
  }
  padding-top: 1.5rem;
  padding-bottom: 0.6rem;
  @media (min-width: 992px) {
    padding-left: 7rem;
    margin-left: 6.5rem;
    padding-right: 1rem;
  }
`

const AverageRating = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.light};
  font-size: 50px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border: 10px solid ${({ theme }) => theme.color.primary};
  margin-top: -4rem;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChevronWrapper = styled.div`
  top: 0;
  & .contrast {
    position: absolute;
    top: 0;
    z-index: 3;
    @media (max-width: 991px) {
      opacity: 0.15;
      top: -2px;
      left: 40%;
    }
  }

  & .transparent {
    position: absolute;
    top: 0;
    left: 2rem;
    z-index: 2;
    @media (max-width: 991px) {
      display: none;
    }

    & path {
      fill: ${({ theme }) => theme.color.grey};
    }
  }
`

const Container = styled.div`
  @media (min-width: 992px) {
    height: 410px;
  }
`

const Col = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 630px;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 991px) {
    z-index: 4;
  }
`

const Content = styled(ParseContent)`
  margin-top: -2.4rem;
  & p {
    font-size: 26px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const ReviewHighlighted: React.FC<ReviewProps> = ({ fields }) => {
  const wrapper = useWrapper()

  const { reviewMeta }: any = wrapper

  return (
    <section className="mb-5 pb-lg-5">
      <Container className="container">
        <div className="row h-100 justify-content-center">
          <div className="col-xl-11 h-100 position-relative">
            <div className="row h-100 justfiy-content-end align-items-center">
              <div className="col-lg-9 col-xl-8">
                <AverageWrapper>
                  <ChevronWrapper className="position-relative">
                    <Chevron className="contrast" />
                    <Chevron className="transparent" />
                  </ChevronWrapper>
                  <AverageBlock className="d-flex flex-column align-items-start position-relative">
                    <div className="d-flex align-items-center justify-content-center">
                      <AverageRating>{reviewMeta?.average}</AverageRating>
                      <div className="d-flex flex-column ms-3">
                        <StarRatings
                          rating={reviewMeta?.average / 2 || 10}
                          starDimension="30px"
                          starSpacing="2px"
                          starRatedColor="rgb(255, 206, 10)"
                          starEmptyColor="rgb(24, 38, 63)"
                          isAggregateRating
                        />
                        <span className="mb-5">
                          uit {reviewMeta?.count} beoordelingen
                        </span>
                      </div>
                    </div>
                    <div className="ms-3 ms-sm-5 mt-3 me-3">
                      <Content content={fields.description} />
                    </div>
                    <div className="my-2 pb-2 ms-3">
                      <AnchorPrimary
                        href={fields.link?.url || '#'}
                        className="text-white"
                        isLight
                      >
                        {fields.link?.title}
                      </AnchorPrimary>
                    </div>
                  </AverageBlock>
                </AverageWrapper>
              </div>
              <Col className="col-lg-3 col-xl-4">
                <Image image={fields.image} alt="" className="h-100" />
              </Col>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default ReviewHighlighted
