import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import StarRatings from 'react-star-ratings'

// Hooks
import useWrapper from 'hooks/useWrapper'

// Images
import ChevronBig from 'img/chevronbig.inline.svg'
import Chevron from 'img/chevron.inline.svg'

// Interface
import { ReviewProps } from 'components/flex/Reviews/ReviewShell'
import NavLink from 'components/shared/NavLink'
import Loading from 'components/elements/Misc/Loading'

const AverageWrapper = styled.div`
  position: relative;
  z-index: 2;
`

const AverageBlock = styled.div`
  background-color: ${({ theme }) => theme.color.primary};

  & span {
    color: ${({ theme }) => theme.color.light};
    opacity: 0.8;
  }

  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-size: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
  @media (min-width: 992px) {
    padding-left: 7rem;
    margin-left: 6.5rem;
    padding-right: 1rem;
  }
  @media (min-width: 576px) {
    padding-top: 4.8rem;
    padding-bottom: 4.4rem;
  }
  @media (max-width: 575px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`

const AverageRating = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.light};
  font-size: 50px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border: 10px solid ${({ theme }) => theme.color.primary};
  min-width: 120px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChevronWrapper = styled.div`
  top: 0;
  & .contrast {
    position: absolute;
    top: 0;
    z-index: 3;
    @media (max-width: 991px) {
      opacity: 0.15;
      top: -2px;
      left: 40%;
    }
  }

  & .transparent {
    position: absolute;
    top: 0;
    left: 2rem;
    z-index: 2;
    @media (max-width: 991px) {
      display: none;
    }

    & path {
      fill: ${({ theme }) => theme.color.grey};
    }
  }
`

const Container = styled.div``

const Col = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 630px;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 991px) {
    z-index: 4;
  }
`

const Review = styled.div<{ open: boolean }>`
  background-color: ${({ theme }) => theme.color.light};

  & button svg {
    ${(props) =>
      props.open
        ? css`
            transform: rotate(90deg);
          `
        : css`
            transform: rotate(0deg);
          `}
  }
`

const TopContainer = styled.div``

const Rating = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.light};
  font-size: 50px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border-radius: 50%;

  margin-left: -1rem;
  border: 8px solid #ffffff;
  margin-top: -3rem;
  @media (min-width: 576px) {
    width: 120px;
    height: 120px;
  }
  @media (max-width: 575px) {
    width: 100px;
    height: 100px;
  }
`

const ReviewAuthor = styled.span`
  font-size: 18px;
`

const DateSince = styled.div`
  position: absolute;
  margin-left: auto;
  width: 120px;
  top: 5px;
  right: -10px;
  font-size: 12px;
`

const ReviewTitle = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.extraBold};
  font-size: 18px;
`

const ReviewContent = styled.p`

`

const SideTitle = styled(NavLink)`
  position: absolute;
  z-index: 2;
  font-size: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.light};
  opacity: 0.3;

  &:hover {
    color: ${({ theme }) => theme.color.secondary};
    opacity: 1;
  }
  @media (min-width: 576px) {
    transform: rotate(-90deg);
    right: -60px;
    bottom: calc(50% - 25px);
  }
  @media (max-width: 575px) {
    right: 25px;
    top: 0;
  }
`

const Reviews: React.FC<ReviewProps> = ({ fields }) => {
  const wrapper = useWrapper()

  const { reviewMeta }: any = wrapper

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  return (
    <section className="mb-5 pb-lg-5">
      <Container className="container">
        {reviewMeta?.loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center">
            <h2 className="ms-2">Reviews aan het laden</h2>
            <Loading type="black" />
          </div>
        ) : (
          <div className="row h-100 justify-content-center mb-5">
            <div className="col-lg-11 h-100 position-relative">
              <div className="row h-100 justfiy-content-end align-items-center">
                <div className="col-lg-12 mb-5 pb-lg-5">
                  <AverageWrapper>
                    <SideTitle to={fields.link?.url || '#'}>
                      {fields.link?.title}
                    </SideTitle>
                    <ChevronWrapper className="position-relative">
                      <ChevronBig className="contrast" />
                      <ChevronBig className="transparent" />
                    </ChevronWrapper>
                    <AverageBlock className="d-flex flex-column flex-sm-row align-items-start position-relative">
                      <div>
                        <AverageRating>{reviewMeta?.average}</AverageRating>
                      </div>
                      <div className="d-flex flex-column ms-5 pe-5">
                        <div className="mb-4">
                          <StarRatings
                            rating={reviewMeta?.average / 2 || 10}
                            starDimension="30px"
                            starSpacing="2px"
                            starRatedColor="rgb(255, 206, 10)"
                            starEmptyColor="rgb(24, 38, 63)"
                            isAggregateRating
                          />
                        </div>

                        <h2>{`${reviewMeta?.recommendation}% van onze klanten beveelt ons aan`}</h2>
                        <div className="mt-2">
                          <span className="mb-5">
                            Op basis van {reviewMeta?.count} beoordelingen
                          </span>
                        </div>
                      </div>
                    </AverageBlock>
                  </AverageWrapper>
                </div>
                <Col className="col-lg-4">
                  <Image image={fields.image} alt="" />
                </Col>
              </div>
            </div>
          </div>
        )}

        <div className="row" id="reviews">
          {reviewMeta?.reviews.map((review: any, index: number) => {

            const reviewElements = review.elements.filter(
              (e: any) => e.name === 'reviewComments'
            )

            // Rating
            const ratingData = review.elements.filter(
              (e: any) => e.name === 'rating'
            )
            const { text: rating } = ratingData[0].elements[0]

            // Author
            const reviewAuthorData = review.elements.filter(
              (e: any) => e.name === 'reviewAuthor'
            )
            const { text: reviewAuthor } = reviewAuthorData[0].elements[0]

            // City
            const cityData = review.elements.filter(
              (e: any) => e.name === 'city'
            )
            const { text: city } = cityData[0].elements[0]

            // Date
            const dateSinceData = review.elements.filter(
              (e: any) => e.name === 'dateSince'
            )
            const { text: dateSince } = dateSinceData[0].elements[0]

            // Content
            const reviewContentData = review.elements.filter(
              (e: any) => e.name === 'reviewContent'
            )

            const contentElements =
              reviewContentData[0].elements[2].elements.filter(
                (e: any) => e.name === 'rating'
              )

            const titleElements =
              reviewContentData[0].elements[1].elements.filter(
                (e: any) => e.name === 'rating'
              )

            const { text: reviewContent } = contentElements[0].elements[0]
            const { text: reviewTitle } = titleElements[0].elements[0]

            const open: boolean = currentIndex === index

            const date = new Date(dateSince)

            const localeDate = date.toLocaleDateString('nl-NL', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })

            return (
              <>
                {/* eslint-disable-next-line react/no-array-index-key */}
                <div className="col-lg-6 mb-5 pb-5" key={index}>
                  <Review className="ps-5 pe-3 py-2 mx-3 " open={open}>
                    <TopContainer className="row position-relative mb-4">
                      <Rating className="col-lg-4 d-flex justify-content-center align-items-center">
                        {rating}
                      </Rating>
                      <div className="col-lg-6 d-flex flex-column align-items-center">
                        <div className="d-flex flex-column">
                          <StarRatings
                            rating={rating / 2 || 10}
                            starDimension="30px"
                            starSpacing="2px"
                            starRatedColor="rgb(255, 206, 10)"
                            starEmptyColor="rgb(24, 38, 63)"
                            isAggregateRating
                          />
                          <ReviewAuthor>{`${reviewAuthor}, ${city}`}</ReviewAuthor>
                        </div>
                      </div>
                      <DateSince>{localeDate}</DateSince>
                    </TopContainer>
                    <div>
                      <ReviewTitle>{reviewTitle}</ReviewTitle>
                      <ReviewContent>
                        {reviewContent}
                        {reviewElements && reviewElements.length > 0 && (
                          <>
                            <p className="mt-3 mb-0"><strong>Reactie:</strong></p> 
                            {reviewElements[0].elements[0].text}
                          </>
                        )}
                      </ReviewContent>
                      {/* <div className="d-flex justify-content-end">
                        {reviewContent.length > 210 && (
                          <button
                            type="button"
                            onClick={() =>
                              !open
                                ? setCurrentIndex(index)
                                : setCurrentIndex(null)
                            }
                            className="d-flex align-items-center"
                          >
                            {!open ? (
                              <span>Lees meer</span>
                            ) : (
                              <span>Lees minder</span>
                            )}
                            <Chevron className="ms-2" />
                          </button>
                        )}
                      </div> */}
                    </div>
                  </Review>
                </div>
              </>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

export default Reviews
