import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import StarRatings from 'react-star-ratings'

// Hooks
import useWrapper from 'hooks/useWrapper'

// Images
import ChevronBig from 'img/chevronbig.inline.svg'

// Interface
import { ReviewProps } from 'components/flex/Reviews/ReviewShell'
import NavLink from 'components/shared/NavLink'
import Loading from 'components/elements/Misc/Loading'

const AverageWrapper = styled.div`
  position: relative;
  z-index: 2;
`

const AverageBlock = styled.div`
  background-color: ${({ theme }) => theme.color.primary};

  & span {
    color: ${({ theme }) => theme.color.light};
    opacity: 0.8;
  }

  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-size: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
  @media (min-width: 992px) {
    padding-left: 7rem;
    margin-left: 6.5rem;
    padding-right: 1rem;
  }
  @media (min-width: 576px) {
    padding-top: 4.8rem;
    padding-bottom: 4.4rem;
  }
  @media (max-width: 575px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`

const AverageRating = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.light};
  font-size: 50px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border: 10px solid ${({ theme }) => theme.color.primary};
  min-width: 120px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChevronWrapper = styled.div`
  top: 0;
  & .contrast {
    position: absolute;
    top: 0;
    z-index: 3;
    @media (max-width: 991px) {
      opacity: 0.15;
      top: -2px;
      left: 40%;
    }
  }

  & .transparent {
    position: absolute;
    top: 0;
    left: 2rem;
    z-index: 2;
    @media (max-width: 991px) {
      display: none;
    }

    & path {
      fill: ${({ theme }) => theme.color.grey};
    }
  }
`

const Container = styled.div``

const Col = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 630px;
  }
`

const Image = styled(Plaatjie)`
  @media (max-width: 991px) {
    z-index: 4;
  }
`

const SideTitle = styled(NavLink)`
  position: absolute;
  z-index: 2;
  font-size: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.light};
  opacity: 0.3;

  &:hover {
    color: ${({ theme }) => theme.color.secondary};
    opacity: 1;
  }
  @media (min-width: 576px) {
    transform: rotate(-90deg);
    right: -60px;
    bottom: calc(50% - 25px);
  }
  @media (max-width: 575px) {
    right: 25px;
    top: 0;
  }
`

const ReviewsLink: React.FC<ReviewProps> = ({ fields }) => {
  const wrapper = useWrapper()

  const { reviewMeta }: any = wrapper

  return (
    <section>
      <Container className="container">
        {reviewMeta?.loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center">
            <h2 className="ms-2">Reviews aan het laden</h2>
            <Loading type="black" />
          </div>
        ) : (
          <div className="row h-100 justify-content-center">
            <div className="col-lg-11 h-100 position-relative">
              <div className="row h-100 justfiy-content-end align-items-center">
                <div className="col-lg-12 pb-3 pb-lg-5">
                  <AverageWrapper>
                    <SideTitle to={fields.link?.url || '#'}>
                      {fields.link?.title}
                    </SideTitle>
                    <ChevronWrapper className="position-relative">
                      <ChevronBig className="contrast" />
                      <ChevronBig className="transparent" />
                    </ChevronWrapper>
                    <AverageBlock className="d-flex flex-column flex-sm-row align-items-start position-relative">
                      <div>
                        <AverageRating>{reviewMeta?.average}</AverageRating>
                      </div>
                      <div className="d-flex flex-column ms-5 pe-5">
                        <div className="">
                          <StarRatings
                            rating={reviewMeta?.average / 2 || 10}
                            starDimension="30px"
                            starSpacing="2px"
                            starRatedColor="rgb(255, 206, 10)"
                            starEmptyColor="rgb(24, 38, 63)"
                            isAggregateRating
                          />
                        </div>

                        <h2>{`${reviewMeta?.recommendation}% van onze klanten beveelt ons aan`}</h2>
                        <div className="mt-2">
                          <span className="mb-5">
                            Op basis van {reviewMeta?.count} beoordelingen
                          </span>
                        </div>
                      </div>
                    </AverageBlock>
                  </AverageWrapper>
                </div>
                <Col className="col-lg-4">
                  <Image image={fields.image} alt="" />
                </Col>
              </div>
            </div>
          </div>
        )}
      </Container>
    </section>
  )
}

export default ReviewsLink
